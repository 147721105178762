
@font-face {
    font-family: 'Avenir';
    src: url('./AvenirLTStd-Roman.otf') format('opentype');
    /* Add additional font formats if necessary (e.g., woff, woff2) */
  }

  
@font-face {
    font-family: 'Avenir-Black';
    src: url('./AvenirLTStd-Black.otf') format('opentype');
    /* Add additional font formats if necessary (e.g., woff, woff2) */
  }